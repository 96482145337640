import Home from './portfolio/home';
import Desktop from './pc/desktop';
import TitleBar from './home/titlebar';
function App() {
  return (
    <div className='bg-[#f5f5f5]'>
      {/*<Desktop title={'About'}/>*/}
      <TitleBar className='h-[10%]'/>
      <Home className='h-[90%]'/>
      {/* <Home title={'About'}/> */}
    </div>
  );
}

export default App;
