import "../App.css";
import { ReactComponent as D2 } from "../assests/images/doodle.svg";
function Home() {
  return (
    <div className="bg-[#F5F5F5] h-screen w-screen">
      <div className="grid grid-cols-1 lg:grid-cols-12 m-[5%] bg-[#F5F5F5]">
        <div className="lg:col-start-2 lg:col-span-5 flex flex-col justify-center max-md:items-center">
          <span className="text-[20pt] lg:text-[64pt] font-playwriteUSModern">Tahmid Islam</span>
          <span className="text-[10pt] lg:text-[32pt] font-serif">I am a Junior Software Developer</span>
        </div>
        <div className="lg:col-start-7 lg:col-span-5 flex lg:justify-end justify-center">
          <D2 className="object-fill-current visible md:w-[540px] md:h-[540px] w-[200px] h-[200px] m-6" />
        </div>
      </div>
    </div>
  );
}

export default Home;
