/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { MailOutlined } from '@ant-design/icons';

function TitleBar() {
    return (
        // <div className='px-[170px] py-[70px] px-14 bg-[#F5F5F5] font-sans'>
        //     <nav className='flex justify-between'>
        // <div className='text-[20px] font-medium font-montserrat'>Tahmid Islam</div>
        //         <ul className='flex text-xl font-medium'>
        // <li className='mx-6 text-[16px]'><a href="#">Home</a></li>
        // <li className='mx-6 text-[16px]'><a href="#">About</a></li>
        // <li className='mx-6 text-[16px]'><a href="#">Work</a></li>
        // <li className='mx-6 text-[16px]'><a href="#">Contact</a></li>
        //             <li>
        // <button className='mx-6 text-[16px] w-[80px] h-[30px] border-black border rounded-xl flex flex-row justify-center items-center m-auto'>
        //     <MailOutlined className='text-[16px]' />
        //     <label className='ml-2'>Mail</label>
        // </button>
        //             </li>
        //         </ul>
        //     </nav>
        // </div>

        <nav className="bg-[#F5F5F5] border-gray-200 ">
            <div className="xl:max-w-screen-xl lg:max-w-screen-lg md:max-w-md sm:max-h-sm flex flex-wrap items-center justify-between mx-auto p-4">
                <div className="text-[20px] font-medium font-montserrat flex items-center space-x-3 rtl:space-x-reverse">Tahmid Islam</div>
                <div className="flex md:order-2 space-x-3 md:space-x-0 rtl:space-x-reverse">
                    <button aria-disabled="navbar-cta" className="aria-disabled:invisible mx-6 text-[16px] w-[80px] h-[30px] border-black border rounded-xl flex flex-row justify-center items-center m-auto px-4 py-2 text-center">
                        <MailOutlined className="text-[16px]" />
                        <label className="ml-2">Mail</label>
                    </button>
                    {/* <button type="button" className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Get started</button> */}
                    <button data-collapse-toggle="navbar-cta" type="button" className="inline-flex items-center p-2 w-8 h-8 justify-center text-sm text-black rounded-lg md:hidden hover:bg-[#F5F5F5] focus:outline-none focus:ring-2 focus:ring-black" aria-controls="navbar-cta" aria-expanded="false">
                        <span className="sr-only">Open main menu</span>
                        <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 1h15M1 7h15M1 13h15" />
                        </svg>
                    </button>
                </div>
                <div className="items-center justify-between hidden w-full md:flex md:w-auto md:order-1" id="navbar-cta">
                    <ul className="flex flex-col font-medium p-4 md:p-0 mt-4 border border-gray-100 rounded-lg bg-gray-50 md:space-x-8 rtl:space-x-reverse md:flex-row md:mt-0 md:border-0 md:bg-[#F5F5F5]">
                        <li>
                            <a href="#" className="block py-2 px-3 md:p-0 text-white bg-black rounded md:bg-transparent md:text-black" aria-current="page">
                                Home
                            </a>
                        </li>
                        <li>
                            <a href="#" className="block py-2 px-3 md:p-0 text-white bg-black rounded md:bg-transparent md:text-black">
                                About
                            </a>
                        </li>
                        <li>
                            <a href="#" className="block py-2 px-3 md:p-0 text-white bg-black rounded md:bg-transparent md:text-black">
                                Services
                            </a>
                        </li>
                        <li>
                            <a href="#" className="block py-2 px-3 md:p-0 text-white bg-black rounded md:bg-transparent md:text-black">
                                Contact
                            </a>
                        </li>
                        {/* <li>
                            <button className="mx-6 text-[16px] w-[80px] h-[30px] border-black border rounded-xl flex flex-row justify-center items-center m-auto">
                                <MailOutlined className="text-[16px]" />
                                <label className="ml-2">Mail</label>
                            </button>
                        </li> */}
                    </ul>
                </div>
            </div>
        </nav>
    );
}

export default TitleBar;
